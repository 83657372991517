import { ref, reactive } from "vue";
import * as API from "@/API/payment";
import downloadFile from '@/utils/rv/downloadFile'
import moment from 'moment';

type SearchParams = {
    dealerCode: string;
    vendorCode: string;
    dealerNameEn: string;
    dealerNameZh: string;
    entity: string;
}

export interface SalesTableData {
    dealerCode: string;
    vendorCode: string;
    dealerNameEn: string;
    dealerNameZn: string;
}

const useDealerVendorCodeSet = () => {
    const entityRef = ref()
    const searchParams = reactive<SearchParams>({
        dealerCode: '',
        vendorCode: '',
        dealerNameEn: '',
        dealerNameZh: '',
        entity: 'MBCL'
    })
    const total = ref<number>(0)
    const pageNumber = ref<number>(0)
    const pageSize = ref<number>(10)
    const columnsData = [
        { title: "Dealer Code", dataIndex: "dealerCode", width: 150},
        { title: "Vendor Code", dataIndex: "vendorCode", width: 150},
        { title: "Dealer Name (EN)", dataIndex: "dealerNameEn"},
        { title: "Dealer Name (ZH)", dataIndex: "dealerNameZn"}
    ]
    const tableData = ref<SalesTableData[]>()
    const getTableData = () => {
        const params = {
            dealerCode: searchParams.dealerCode,
            vendorCode: searchParams.vendorCode,
            dealerNameEn: searchParams.dealerNameEn,
            dealerNameZn: searchParams.dealerNameZh,
            entity: searchParams.entity,
            pageNumber: pageNumber.value,
            pageSize: pageSize.value
          }
        API.getVendorList(params).then(data => {
            tableData.value = data.content
            total.value = data.totalElements
        })
        console.log('获取表格数据')
    }
    const doSearch = () => {
        searchParams.dealerCode = searchParams.dealerCode ? searchParams.dealerCode : ""
        searchParams.vendorCode = searchParams.vendorCode ? searchParams.vendorCode : ""
        searchParams.dealerNameEn = searchParams.dealerNameEn ? searchParams.dealerNameEn : ""
        searchParams.dealerNameZh = searchParams.dealerNameZh ? searchParams.dealerNameZh : ""
        searchParams.entity = searchParams.entity ? searchParams.entity : ""
        pageNumber.value = 1
        getTableData()
    }
    const doReset = () => {
        Object.assign(searchParams, {
            dealerCode: '',
            vendorCode: '',
            dealerNameEn: '',
            dealerNameZh: '',
            entity: 'MBCL'
        })
        // entityRef.value.reset()
        tableData.value = []
        doSearch()
    }
    const pageChange = (page: number, size: number) => {
        pageNumber.value = page > 0 ? page : 1
        pageSize.value = size
        getTableData()
    };
    const sizeChange = (page: number, size: number) => {
        pageNumber.value = page > 0 ? page : 1
        pageSize.value = size
        getTableData()
    }
    const isUploadVisible = ref<boolean>(false)
    const exportClick = () => {
        const params = {
            url: '/ptapi/vendor/export',
            method: 'post',
            params: {
                dealerCode: searchParams.dealerCode,
                dealerNameEn: searchParams.dealerNameEn,
                dealerNameZn: searchParams.dealerNameZh,
                entity: searchParams.entity,
                pageNumber: pageNumber.value,
                pageSize: pageSize.value,
                vendorCode: searchParams.vendorCode
            },
            fileName: searchParams.entity + '_' + moment().format('YYYYMMDD') + '.xlsx'
        }
        downloadFile(params)
    }
    const uploadClick = () => {
        isUploadVisible.value = true
    }
    const closeUpload = (param: { isUploadVisible: boolean }) => {
        isUploadVisible.value = param.isUploadVisible
    }
    const refreshTable =()=>{
        closeUpload({ isUploadVisible: false })
        getTableData()
    }
    return {
        searchParams,
        columnsData,
        tableData,
        doSearch,
        doReset,
        pageChange,
        sizeChange,
        pageSize,
        pageNumber,
        total,
        isUploadVisible,
        exportClick,
        uploadClick,
        closeUpload,
        refreshTable,
        entityRef
    }
}
export default useDealerVendorCodeSet