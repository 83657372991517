
import { ref, defineComponent } from 'vue'
import useDealerVendorCodeSet from "@/hooks/payment/EntitySiPayout/useDealerVendorCodeSet";
import { ColumnProps } from 'ant-design-vue/es/table/interface';
// import Pagination from "@/components/Pagination.vue";
import TableWrapper from "@/components/TableWrapper.vue";
import UploadDetail from '@/views/Payment/EntitySiPayout/Vendor/uploadDetail.vue'
import EntitySelector from "@/views/Payment/components/EntitySelector.vue";
import { ExclamationOutlined } from '@ant-design/icons-vue';
import { getVendorCodeBlankButtonHighlight, getVendorCodeBlankList } from '@/API/payment'
type Key = ColumnProps['key'];
const tableWidth = window.innerWidth
const tableHeight = window.innerHeight-458
export default defineComponent({
  name: 'VehicleNameSet',
  components: {
    UploadDetail,
    // Pagination,
    TableWrapper,
    EntitySelector,
    ExclamationOutlined
  },
  props: {},
  setup() {
    const {
      searchParams,
      columnsData, tableData, doSearch, doReset, pageChange, sizeChange, pageSize, pageNumber, total,
      isUploadVisible, exportClick, uploadClick, closeUpload, refreshTable, entityRef
    } = useDealerVendorCodeSet()
    // 获取Entity下拉列表
    doSearch();
    const handlePageChange = (page: number,pageSize: number) =>{
      pageChange(page, pageSize)
    }
    const handleSizeChange = (page: number,pageSize: number) =>{
      sizeChange(page, pageSize)
    }

    const vendorCodeBlankDisabled = ref(true)
    const vendorCodeBlankModalVisible = ref(false)

    const vendorCodeBlankTableColumns = [
      {
        title: 'Dealer Code',
        dataIndex: 'dealerCode',
        key: 'dealerCode',
        width: 100
      },
      {
        title: 'Chinese Name',
        dataIndex: 'dealerNameCn',
        key: 'dealerNameCn',
        width: 300
      },
      {
        title: 'English Name',
        dataIndex: 'dealerNameEn',
        key: 'dealerNameEn',
        width: 400
      },
      {
        title: 'Update User',
        dataIndex: 'updatedBy',
        key: 'updatedBy',
        width: 100
      },
      {
        title: 'Update Time',
        dataIndex: 'updatedAt',
        key: 'updatedAt',
        width: 150
      },
    ]
    const vendorCodeBlankTableData = ref<Record<string, string>[]>([])

    const handleShowVendorCodeBlank = async () => {
      vendorCodeBlankTableData.value = await getVendorCodeBlankList()
      vendorCodeBlankModalVisible.value = true
    }

    const refreshVendorCodeBlankButtonHighlight = async () => {
      vendorCodeBlankDisabled.value = !(await getVendorCodeBlankButtonHighlight())
    }

    const handleChangeTable = () => {
      refreshTable()
      refreshVendorCodeBlankButtonHighlight()
    }

    refreshVendorCodeBlankButtonHighlight()

    getVendorCodeBlankButtonHighlight().then(highlight => {
      vendorCodeBlankDisabled.value = !highlight
    })
    return {
      searchParams,
      doSearch,
      doReset,
      tableWidth,
      tableHeight,
      columnsData,
      tableData,
      pageSize,
      pageNumber,
      total,
      handlePageChange,
      handleSizeChange,
      isUploadVisible,
      exportClick,
      uploadClick,
      closeUpload,
      refreshTable,
      entityRef,
      vendorCodeBlankDisabled,
      vendorCodeBlankModalVisible,
      vendorCodeBlankTableColumns,
      vendorCodeBlankTableData,
      handleShowVendorCodeBlank,
      handleChangeTable
    }
  }
})
